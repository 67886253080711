import './App.css';
import React, { useEffect, useState } from 'react';
import {FileManagerComponent, NavigationPane, Toolbar, DetailsView, Inject} from '@syncfusion/ej2-react-filemanager';
import NotificationPanel from './NotificationPanel';

function App() {
  const [memberId, setMemberId] = useState(null);
  const host_URL = "https://filev3provider.iadsnetwork.com/";
  const guid_URL = "https://ws.iadsnetwork.com/tsadmin.svc/getadminuser?guid=";

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const guid = urlParams.get('guid');
    if(guid) {
      fetch(guid_URL + guid)
        .then(response => response.json())
        .then(data => {
          setMemberId(data.d.memberid);
        })
        .catch(error => console.error('Error:', error));
    }
  }, []);

  if(!memberId) {
    return <div>Loading...</div>
  }

  return (
    <div className="App">
      <div className="Panel">
      <NotificationPanel memberId={memberId} />
      </div>
      <FileManagerComponent height="85vh" cssClass="fm" view='Details' navigationPaneSettings={{ visible: false }} rootAliasName="Home"
        toolbarSettings={{ items: ['NewFolder', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection'], visible: true }}
        ajaxSettings={{
          url: host_URL + "api/FileManager/FileOperations/" + memberId,
          downloadUrl: host_URL + "api/FileManager/Download/" + memberId,
          uploadUrl: host_URL + "api/FileManager/Upload/" + memberId,
          getImageUrl: host_URL + "api/FileManager/GetImage/" + memberId,
        }}
        //Max file size is 500Mb
        uploadSettings={{ minFileSize: 0, maxFileSize: 5e+8 }}>
        <Inject services={[NavigationPane, Toolbar, DetailsView]}></Inject>
      </FileManagerComponent>
    </div>
  );
}

export default App;


// import './App.css';
// import React, { useEffect, useState } from 'react';
// import {FileManagerComponent, NavigationPane, Toolbar, DetailsView, Inject} from '@syncfusion/ej2-react-filemanager';

// function App() {
//   const [memberId, setMemberId] = useState(null);
//   const [email, setEmail] = useState(null);
//   const host_URL = "https://filev3provider.iadsnetwork.com/";
//   const guid_URL = "https://ws.iadsnetwork.com/tsadmin.svc/getadminuser?guid=";

//   useEffect(() => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const guid = urlParams.get('guid');
//     if(guid) {
//       fetch(guid_URL + guid)
//         .then(response => response.json())
//         .then(data => setMemberId(data.d.memberid))
//         .catch(error => console.error('Error:', error));
//     }
//   }, []);

//   if(!memberId) {
//     return <div>Loading...</div>
//   }

//   return (
//     <div className="App">
//       <FileManagerComponent height="95vh" cssClass="fm" view='Details' navigationPaneSettings={{ visible: false }} rootAliasName="Home"
//         toolbarSettings={{ items: ['NewFolder', 'Upload', 'Cut', 'Copy', 'Paste', 'Delete', 'Download', 'Rename', 'SortBy', 'Refresh', 'Selection'], visible: true}}
//         ajaxSettings={{
//         url: host_URL+"api/FileManager/FileOperations/"+memberId,
//         downloadUrl: host_URL+"api/FileManager/Download/"+memberId,
//         uploadUrl: host_URL+"api/FileManager/Upload/"+memberId,
//         getImageUrl: host_URL + "api/FileManager/GetImage/" + memberId,
//       }}>
//         <Inject services={[NavigationPane, Toolbar, DetailsView]}></Inject>
//       </FileManagerComponent>
//     </div>
//   );
// }

// export default App;

