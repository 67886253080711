import React, { MouseEvent, useEffect, useState } from "react";
// import { Typography, Link, Box } from '@mui/core';
import Box from "@mui/material/Box";
import {
  Typography,
  Link,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";

interface NotificationPanelProps {
  memberId: string;
}

const NotificationPanel: React.FC<NotificationPanelProps> = ({ memberId }) => {
  const [email, setEmail] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const email_URL = `https://ws.iadsnetwork.com/tsadmin.svc/getemailrep?memberid=${memberId}&issendmail=0&isasap=0`;

  useEffect(() => {
    fetch(email_URL)
      .then((response) => response.json())
      .then((data) => setEmail(data.d.email))
      .catch((error) => console.error("Error:", error));
  }, [memberId]);

  const handleClickNormal = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    // Fetch the data from the API
    const result = await fetch(
      `https://ws.iadsnetwork.com/tsadmin.svc/getemailrep?memberid=${memberId}&issendmail=1&isasap=0`
    )
      .then((response) => response.json())
      .catch((error) => console.error("Error:", error));

    // If the request was successful, open the dialog with a different message
    if (result) {
      setDialogMessage(
        "Thank you, we will post your pages as at the normal scheduled time."
      );
      setOpen(true);
    }
  };

  const handleClickASAP = async (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    // Fetch the data from the API
    const result = await fetch(
      `https://ws.iadsnetwork.com/tsadmin.svc/getemailrep?memberid=${memberId}&issendmail=1&isasap=1`
    )
      .then((response) => response.json())
      .catch((error) => console.error("Error:", error));

    // If the request was successful, open the dialog with a different message
    if (result) {
      setDialogMessage(
        "Thank you, we will post your pages as soon as possible."
      );
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!email) {
    return <div>Loading...</div>;
  }

  return (
    <Box mt={2}>
      <Typography variant="h6">
        When finished uploading, click one of the links below:
      </Typography>
      <Typography variant="body1">
        <Link
          href="#"
          className="email-normal"
          id="sendMail"
          onClick={handleClickNormal}
        >
          Finish - Post at the scheduled time
        </Link>
      </Typography>
      <Typography variant="body1">
        <Link
          href="#"
          className="email-normal"
          id="sendMailASAP"
          onClick={handleClickASAP}
        >
          Finish - Post ASAP
        </Link>
      </Typography>
      <Typography variant="body1">
        For special requests, email your upload representative at{" "}
        <Link href={`mailto:${email}`} id="mailtoRep">
          {email}
        </Link>
      </Typography>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default NotificationPanel;

// import React, { MouseEvent } from 'react';

// interface NotificationPanelProps {
//   memberId: string;
// }

// const NotificationPanel: React.FC<NotificationPanelProps> = ({ email }) => {
//   const handleClickNormal = (event: MouseEvent<HTMLAnchorElement>) => {
//     event.preventDefault();
//     // Use email in your functionality
//     console.log(`Normal Click Handler. Email: ${email}`);
//     // Replace with your own functionality
//   };

//   const handleClickASAP = (event: MouseEvent<HTMLAnchorElement>) => {
//     event.preventDefault();
//     // Use email in your functionality
//     console.log(`ASAP Click Handler. Email: ${email}`);
//     // Replace with your own functionality
//   };

//   return (
//     <div className="panel radius" style={{marginTop: '-10px'}}>
//       <h6>When finished uploading, click one of the links below:</h6>
//       <p><a href="#" className="email-normal" id="sendMail" onClick={handleClickNormal}>Finish - Post at the scheduled time</a></p>
//       <p><a href="#" className="email-normal" id="sendMailASAP" onClick={handleClickASAP}>Finish - Post ASAP</a></p>
//       <p>For special requests, email your upload representative at <a href={`mailto:${email}`} id="mailtoRep">{email}</a></p>
//     </div>
//   );
// }

// export default NotificationPanel;
